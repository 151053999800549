import React, { Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import OntDetailsSection from './OntDetailsSection';
import { ValidationMessage } from './ValidationMessage';

import * as validators from '../../libs/validators';

const formValidators = [
  {
    field: 'ontReference',
    message: 'Please select a provision type.',
    fn: v => {
      return validators.RequiredValidate(v);
    },
  },
  {
    field: 'port',
    message: 'Please select an ONT port.',
    fn: v => {
      return validators.RequiredValidate(v);
    },
  },
];

export default class ProvisionTypeSection extends React.Component {
  state = {
    selectedOnt: {},
    provideType: '',
    validationMessages: [],
    order: {
      ontReference: '',
      port: 0,
    },
    isNewProvide: false
  };

  getPreselectedPort(provideType, ports) {
    let validPorts = [];
    if (provideType === 'Migration') validPorts = ports.filter(p => p.Status === 'Working');
    if (provideType === 'Succession Provide') validPorts = ports.filter(p => p.Status === 'Spare');

    return validPorts.length === 1 ? validPorts[0].Number : null;
  }

  componentDidMount = () => {
    this.props.registerValidator(this.validate);
    this.props.registerGatherer(this.buildOrder);

    if (this.props.ontDetails && this.props.ontDetails.length === 1) {
      this.handleDefaultSelections();
    }
  };

  handleDefaultSelections = () => {
    const ont = this.props.ontDetails[0];
    let provideType = '';

    if (ont.IsMigration && !ont.IsSuccession) {
      provideType = 'Migration';
    } else if (!ont.IsMigration && ont.IsSuccession) {
      provideType = 'Succession Provide';
    }

    this.setState({
      selectedOnt: ont,
      provideType,
      order: {
        ontReference: ont.Reference,
        port: this.getPreselectedPort(provideType, ont.Ports),
      },      
    });

    this.isNewProvide(false);
  };

  buildOrder = () => {
    return this.showOntDetails()
      ? {
          OntDetails: this.state.order,
        }
      : null;
  };

  mapLabel = label => {
    const labels = {
      NewProvide: 'New Provide',
      Migration: 'Migration',
      SimProvide: 'Sim Provide',
      SuccessionProvide: 'Succession Provide',
      MigrationOrSuccessionProvide: 'Migration Or Succession Provide',
    };
    return label ? labels[label] : 'Unknown';
  };

  showOntDetails() {
    const { ontDetails, productType } = this.props;
    return ontDetails && ontDetails.length > 0 && productType === 'FTTP';
  }

  validate = field => {
    if (!this.showOntDetails() || this.state.isNewProvide) return [];

    const validatorsToRun = !field ? formValidators : formValidators.filter(x => x.field === field);

    let validationMessages = !field ? [] : [...this.state.validationMessages].filter(x => x.field !== field);

    for (var validator of validatorsToRun) {
      const value = this.state.order[validator.field];
      const validationResult = validator.fn(value);

      if (!validationResult) {
        const error = { field: validator.field, message: validator.message };
        validationMessages.push(error);
      }
    }

    this.setState({
      validationMessages,
    });

    return validationMessages;
  };

  handleNewProvide = () => {
    this.setState({
      selectedOnt: null,
      provideType: 'New Provide',
      validationMessages: [],
      order: null
    });

    this.isNewProvide(true);
  };

  handleProvisionTypeSelected = (ont, provideType) => {
    if (this.state.ont === ont && this.state.provideType === provideType) return;

    const port = this.getPreselectedPort(provideType, ont.Ports);
    this.setState(
      {
        selectedOnt: ont,
        provideType,
        order: {
          ontReference: ont.Reference,
          port,
        },
      },
      () => {
        const toValidate = port ? null : 'ontReference';
        this.validate(toValidate);
        this.props.SetMinLeadDate(provideType);
      },
    );

    this.isNewProvide(false);
  };

  isNewProvide = isNewProvide => {
    this.setState({ isNewProvide });
    this.props.newProvide({ isNewProvide });
  };

  handlePortSelected = ({ target }) => {
    this.setState(
      {
        order: {
          ...this.state.order,
          port: target.value,
        },
      },
      () => {
        this.validate('port');
      },
    );
  };

  // Render button dynamically using Semantic UI
  renderButton = (id, text, onClick, isSelected) => {
    return (
      <Button
        id={id}
        onClick={onClick}
        primary={isSelected}
        style={{ width: "200px", height: '60px' }}
      >
        <strong>{text}</strong>
      </Button>
    );
  };

  render() {
    const { ontDetails, label } = this.props;
    const { validationMessages } = this.state;
    const showOntDetails = this.showOntDetails();
    const friendlyLabel = this.mapLabel(label);

    return (
      <Fragment>
        <div className="productLabel">{friendlyLabel}</div>
        {showOntDetails && (
          <Fragment>
            {label === 'MigrationOrNewProvide' && (
              this.renderButton(
                'newProvideButton',
                'New Provide',
                this.handleNewProvide,
                this.state.isNewProvide
              )
            )}
            {ontDetails.map(ont => (
              <div key={ont.Reference}>
                {ont.IsSuccession && 
                  this.renderButton(
                    'successionProvideButton',
                    'Succession Provide',
                    () => this.handleProvisionTypeSelected(ont, 'Succession Provide'),
                    this.state.provideType === 'Succession Provide'
                  )}
                {ont.IsMigration && 
                  this.renderButton(
                    'migrationButton',
                    'Migration',
                    () => this.handleProvisionTypeSelected(ont, 'Migration'),
                    this.state.provideType === 'Migration'
                  )}
              </div>
            ))}
          </Fragment>
        )}
        {showOntDetails && this.state.provideType && (
          <OntDetailsSection
            ontDetails={!this.state.isNewProvide ? this.state.selectedOnt : null}
            provideType={this.state.provideType}
            selectedPort={!this.state.isNewProvide ? this.state.order.port : null}
            onPortSelected={this.handlePortSelected}
            validationMessage={validationMessages.find(v => v.field === 'port')}
          />
        )}
      </Fragment>
    );
  }
}