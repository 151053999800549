import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { Form, Grid, Button, Divider } from 'semantic-ui-react';
import { AppointmentComponent } from './ZenAppointmentComponent';

export const AppointmentRequiredComponent = (props) => {
    const [required, setRequired] = React.useState(false);

    React.useEffect(() => {
        props.onRequiredChange(required);
    }, [required]);

    const handleAppointmentYes = (e) => {
        e.currentTarget.className += ' uiButtonProvideActive';
        document.getElementById("requiresAppointmentNo").classList.remove('uiButtonProvideActive');
        document.getElementById('requiresAppointmentNo').classList.add('uiButtonPorts');
        setRequired(true);
    }

    const handleAppointmentNo = (e) => {
        e.currentTarget.className += ' uiButtonProvideActive';
        document.getElementById("requiresAppointment").classList.remove('uiButtonProvideActive');
        document.getElementById('requiresAppointment').classList.add('uiButtonPorts');
        setRequired(false);
    }

    const renderAppointmentYes = () => {
        return (
            <Button onClick={handleAppointmentYes}
                id='requiresAppointment'
                style={{ width: "200px", height: '60px', marginLeft: '20px' }} className='uiButtonPorts'>
                {'Yes'}
            </Button>
        );
    }

    const renderAppointmentNo = () => {
        return (
            <Button onClick={handleAppointmentNo}
                id='requiresAppointmentNo'
                style={{ width: "200px", height: '60px', marginLeft: '20px' }} className='uiButtonPorts'>
                {'No'}
            </Button>
        );
    }

    if (props.isVisible === true) {
        return (
            <Grid style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '10px' }} columns='equal'>
                <FormGroup>
                    <Form.Field
                        inline
                        label='Appointment'
                        name='appointmentRequired'
                        data-testid='appointmentRequired'
                    />
                </FormGroup>
                {renderAppointmentYes()}
                {renderAppointmentNo()}
                <FormGroup>
                    <Divider></Divider>
                    <AppointmentComponent isVisible={required} today={new Date()} leadTimeDays={5} />
                </FormGroup>
            </Grid>
        );
    } else return null;
}

AppointmentRequiredComponent.propTypes = {
    onRequiredChange: PropTypes.func,
    isVisible: PropTypes.bool.isRequired,
};

AppointmentRequiredComponent.defaultProps = {
    onRequiredChange: () => {},
};