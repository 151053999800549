import React, { Fragment } from 'react';
import { ValidationMessage } from './ValidationMessage';

export default class OntDetails extends React.Component {
  render() {
    const {
      ontDetails,
      provideType,
      selectedPort,
      onPortSelected,
      validationMessage,
    } = this.props;

    if (provideType === 'New Provide') return null;
    if (!ontDetails.Reference) return null;

    const isDisabled = port =>
      (provideType === 'Migration' && port.Status === 'Spare') ||
      (provideType === 'Succession Provide' && port.Status === 'Working');

    const validationClass = validationMessage ? 'input-validation-error' : '';

    return (
      <div className="ont-details-section">
        <div className="section-header">
          <span className="icon glyphicon glyphicon-th-large primary"></span>
          <span className="section-title">Port Selection</span>
        </div>
        <div className="section-content">
          <div>
            {ontDetails.Ports &&
              ontDetails.Ports.map(port => {
                const inUse = port.Status === 'Working' ? 'in use' : 'free';
                const isSelected = port.Number === selectedPort ? 'selectedOption' : '';

                return (
                  <button
                    className={`btn btn-default ProductOptionButton optionSelectionButton ${isSelected} ${validationClass}`}
                    id={`portButton-${port.Number}`}
                    type="button"
                    value={port.Number}
                    disabled={isDisabled(port)}
                    onClick={onPortSelected}
                    key={port.Number}
                  >
                    Port {port.Number} ({inUse})
                  </button>
                );
              })}
          </div>
          <div>
            <ValidationMessage error={validationMessage} />
          </div>
        </div>
      </div>
    );
  }
}
