export const RangeValidate = (min, max, value) => {
  return value.length >= min && value.length <= max;
}

export const RegexValidate = (regex, value) => {
  return value !== null && new RegExp(regex).test(value);
};

export const RequiredValidate = value => {
  return value !== null && value.toString().trim().length ? true : false;
};

export const LengthValidate = (length, value) => {
  return value !== null && value.toString().trim().length === length ? true : false;
};

export const PrefixValidate = (invalidPrefixes, value) => {
  for (var prefix of invalidPrefixes) {
    if (value.startsWith(prefix))
      return false;
  }
  return true;
}

export const PostCodeValidate = (value) => {
  return RegexValidate("^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})$", value)
}

export const IccIdValidate = (iccId) => {
  // Ensure the input is a string and remove any non-digit characters.
  iccId = iccId.replace(/\D/g, '');

  // Check if the ICC ID length is within the typical range.
  if (iccId.length < 19 || iccId.length > 20) {
    return false;
  }

  let sum = 0;
  let shouldDouble = false; // Start with the rightmost digit.
  // Iterate over the ICC ID from right to left.
  for (let i = iccId.length - 1; i >= 0; i--) {
    let digit = parseInt(iccId.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    shouldDouble = !shouldDouble; // Alternate the flag.
  }

  // A valid ICC ID will have a sum that is a multiple of 10.
  return sum % 10 === 0;
};
