import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import Panel from "../Panel";
import { authContext } from "../../containers/AuthProvider";
import { getDiagnostics } from "../../libs/requests/getDiagnostics";

const getSyncStatusLabel = (status) => {
  switch (status) {
    case 0:
      return "Not Specified";
    case 1:
      return "In Sync";
    case 2:
      return "Out of Sync";
    default:
      return "Unknown";
  }
};

const ServiceTest = ({ serviceData }) => {
  const { xDslStatusCheck, lineTest, profileData, lineHistory } =
    serviceData || {};

  return (
    <>
      {/* Sync Information - Last Run Test Detail */}
      <Panel header="Sync Information - Last Run Test Detail">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="sync" /> Sync Status:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {getSyncStatusLabel(xDslStatusCheck?.syncStatus) || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="speedometer" /> Downstream Sync Speed:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {xDslStatusCheck?.syncSpeedDownstream
                  ? `${xDslStatusCheck.syncSpeedDownstream} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="upload" /> Upstream Sync Speed:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {xDslStatusCheck?.syncSpeedUpstream
                  ? `${xDslStatusCheck.syncSpeedUpstream} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="history" /> Retrains Detected:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {xDslStatusCheck?.retrainsDetected || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="warning" /> Estimated Speed (Downstream):
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {xDslStatusCheck?.estimatedSpeedAtProvisioningDownstream
                  ? `${xDslStatusCheck.estimatedSpeedAtProvisioningDownstream} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>

      {/* Line Test Information */}
      <Panel header="Line Test Results">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            {/* Existing Rows */}
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="check circle" /> Test Outcome:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.testOutcome === 1
                  ? "Pass"
                  : lineTest?.testOutcome === 2
                  ? "Fail"
                  : lineTest?.testOutcome === 3
                  ? "Inconclusive"
                  : lineTest?.testOutcome === 4
                  ? "Timed Out"
                  : lineTest?.testOutcome === 5
                  ? "Premature Termination"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="info" /> Test Outcome Description:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.testOutcomeDescription || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="map marker" /> Main Fault Location:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {(() => {
                  const locations = [
                    "Not Specified",
                    "OK",
                    "CA",
                    "CE",
                    "LN",
                    "EX",
                    "FU",
                    "DT",
                    "TO",
                    "LO",
                    "EO",
                    "CO",
                    "DO",
                    "CI",
                  ];
                  return locations[lineTest?.mainFaultLocation] || "Unknown";
                })()}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="plug" /> NTE Power Status:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.ntePowerStatus === 1
                  ? "Power On"
                  : lineTest?.ntePowerStatus === 2
                  ? "Power Off"
                  : lineTest?.ntePowerStatus === 3
                  ? "Unknown"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="wired" /> Estimated Line Length:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.estimatedLineLengthInMetres
                  ? `${lineTest.estimatedLineLengthInMetres} meters`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>

            {/* New Rows for Additional Data */}
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="plug" /> Bridge Tap:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.bridgeTap === 1
                  ? "Detected"
                  : lineTest?.bridgeTap === 2
                  ? "Not Detected"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="wifi" /> Cross Talk:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.crossTalk === 1
                  ? "Detected"
                  : lineTest?.crossTalk === 2
                  ? "Not Detected"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="signal" /> Radio Frequency Ingress:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.radioFrequencyIngress === 1
                  ? "Detected"
                  : lineTest?.radioFrequencyIngress === 2
                  ? "Not Detected"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="wrench" /> Home Wiring Problem:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.homeWiringProblem === 1
                  ? "Detected"
                  : lineTest?.homeWiringProblem === 2
                  ? "Not Detected"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="bolt" /> Repetitive Electrical Impulse Noise:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.repetitiveElectricalImpulseNoise === 1
                  ? "Detected"
                  : lineTest?.repetitiveElectricalImpulseNoise === 2
                  ? "Not Detected"
                  : "Not Specified"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="microchip" /> Voice Reinjection:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.voiceReinjection || "Unknown"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="plug" /> Faceplate:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.faceplate || "Unknown"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="plug" /> NTE5C:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineTest?.ntE5c || "Unknown"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>

      {/* Profile Data */}
      <Panel header="Profile Data">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="profile" /> Downstream Profile:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {profileData?.profileDownstream || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="profile" /> Upstream Profile:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {profileData?.profileUpstream || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="calendar" /> Date of Last Profile Change:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {profileData?.dateOfLastProfileChange
                  ? new Date(
                      profileData.dateOfLastProfileChange
                    ).toLocaleString()
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>

      {/* Line History */}
      <Panel header="Line History">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="chart line" /> Downstream Rate (Avg):
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineHistory?.downstreamRate?.avg
                  ? `${lineHistory.downstreamRate.avg} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="chart line" /> Upstream Rate (Avg):
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineHistory?.upstreamRate?.avg
                  ? `${lineHistory.upstreamRate.avg} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="clock" /> Uptime (Avg):
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineHistory?.uptime?.avg
                  ? `${lineHistory.uptime.avg} hours`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="repeat" /> Retrains (Avg):
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {lineHistory?.retrains?.avg || "N/A"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>
    </>
  );
};

export default function ServiceTestsSOGEA() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isRunningTest, setIsRunningTest] = useState(false);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  const fetchData = async () => {
    try {
      if (permissions.servicesAccess) {
        const response = await getDiagnostics(reference, [
          "ServiceTestSOGEALatest",
        ]);
        setServiceData(response.SogeaServiceTestResponse || {});
        setIsError(false);
      }
    } catch (error) {
      console.error("Error fetching service test data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const runTest = async () => {
    setIsRunningTest(true);
    setIsError(false);
    try {
      const { startSogeaServiceTestResponse } = await getDiagnostics(
        reference,
        ["StartSogeaServiceTest"]
      );
      const { testId } = startSogeaServiceTestResponse;

      const pollTestResult = async () => {
        try {
          const queryString = `testId=${testId}`;
          const result = await getDiagnostics(
            reference,
            ["GetTestResultSogea"],
            "POST",
            {},
            queryString
          );

          if (result?.testResultSogea?.errorMessage) {
            setIsRunningTest(false);
            setIsError(true);
            console.error(
              "Error during test:",
              result.testResultSogea.errorMessage
            );
          } else if (
            result?.testResultSogea &&
            result?.testResultSogea?.dataFrom
          ) {
            setServiceData(result.testResultSogea); // Update the entire state
            setIsRunningTest(false);
          } else {
            setTimeout(pollTestResult, 2000);
          }
        } catch (error) {
          console.error("Error polling test result:", error);
          setIsRunningTest(false);
          setIsError(true);
        }
      };

      pollTestResult();
    } catch (error) {
      console.error("Error starting the service test:", error);
      setIsRunningTest(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [permissions.servicesAccess, reference]);

  const handleAccordionClick = (e, { index }) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  if (isLoading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <Panel header="Service Tests">
      {isError && (
        <Message
          error
          header="Unable to retrieve test data at this time, please try again later."
        />
      )}

      {!serviceData?.serviceTest?.dataFrom && isRunningTest && (
        <Message
          info
          header="Test is in progress, do not navigate away from this page while the test is running, or results cannot be viewed."
        />
      )}

      <Accordion fluid styled>
        <AccordionTitle
          active={activeIndex === 0}
          index={0}
          onClick={handleAccordionClick}
        >
          <Icon name="dropdown" />
          Service Test
        </AccordionTitle>
        <AccordionContent active={activeIndex === 0}>
          <ServiceTest serviceData={serviceData} />
        </AccordionContent>
      </Accordion>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1em",
        }}
      >
        <Button
          color="blue"
          onClick={runTest}
          disabled={isRunningTest}
          loading={isRunningTest}
        >
          Run Test
        </Button>
      </div>
    </Panel>
  );
}
