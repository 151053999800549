import React, { Component, Fragment } from "react";
import "semantic-ui-css/semantic.min.css";
import "./AccessLinesSection.css";

const AccessLineButton = ({
  accessLine,
  onClick,
  selectedLine,
  isValid,
  productName,
}) => {
  const { accessLineId, last2Digits, lineNumber, lineType, serviceType } =
    accessLine;

  const selectedClass =
    selectedLine && selectedLine.accessLineId === accessLine.accessLineId
      ? "selectedOption"
      : "";
  const selectedTakover =
    selectedLine &&
    selectedLine.accessLineId === accessLine.accessLineId &&
    accessLine.isLineTakeover
      ? true
      : false;
  const selectedMigration =
    selectedLine &&
    selectedLine.accessLineId === accessLine.accessLineId &&
    !accessLine.isLineTakeover
      ? true
      : false;

  const validationClass = !isValid ? "input-validation-error" : "";

  const lineTypeMappings = [
    {
      key: "WorkingLine",
      value: (
        <WorkingLineTakeover
          accessLine={accessLine}
          onClick={onClick}
          line={selectedLine}
          validationClass={validationClass}
          productName={productName}
          selectedTakover={selectedTakover}
          selectedMigration={selectedMigration}
        />
      ),
    },
    {
      key: "StoppedLine",
      value: (
        <RestartStoppedLine
          accessLine={accessLine}
          onClick={onClick}
          line={selectedLine}
          validationClass={validationClass}
          selectedClass={selectedClass}
        />
      ),
    },
  ];

  const button =
    lineTypeMappings.find((l) => l.key === lineType)?.value ?? "Unknown Type";

  return (
    <div className="access-line-button">
      <button className={`ui button primary ${selectedClass} ${validationClass}`} disabled>
        <table className="buttonOptionsTable">
          <thead>
            <tr>
              <th>Line ID</th>
              <th>Service Type</th>
              <th>Line Number</th>
              {/* <th>Last 2 Digits</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{accessLineId}</td>
              <td>{serviceType}</td>
              <td>{lineNumber}</td>
              {/* <td>{last2Digits}</td> */}
            </tr>
          </tbody>
        </table>
      </button>
      {button}
    </div>
  );
};

const WorkingLineTakeover = ({
  accessLine,
  onClick,
  line,
  validationClass,
  productName,
  selectedTakover,
  selectedMigration,
}) => {
  const handleLineTakover = () => {
    accessLine.isLineTakeover = true;
    onClick(accessLine);
  };

  const handleMigration = () => {
    accessLine.isLineTakeover = false;
    onClick(accessLine);
  };

  const isSogea = productName && productName.includes("SOGEA");

  return (
    <div className="working-line-takeover">
      {isSogea && (
        <button
          className={`ui button ${validationClass} ${
            selectedMigration ? "blue" : ""
          }`}
          onClick={handleMigration}
        >
          Migration
        </button>
      )}
      <button
        className={`ui button ${validationClass} ${
          selectedTakover ? "blue" : ""
        }`}
        onClick={handleLineTakover}
      >
        Working Line Takeover
      </button>
    </div>
  );
};

const RestartStoppedLine = ({
  accessLine,
  onClick,
  line,
  validationClass,
  selectedClass,
}) => {
  const handleClick = () => {
    onClick(accessLine);
  };

  return (
    <div className="restart-stopped-line">
      <button
        className={`ui button ${selectedClass} ${validationClass}`}
        onClick={handleClick}
      >
        Restart Stopped Line
      </button>
    </div>
  );
};

class AccessLinesSelection extends Component {
  state = {
    isValid: true,
  };

  componentDidMount() {
    const { registerGatherer, registerValidator } = this.props;
    registerGatherer(this.buildOrder);
    registerValidator(this.validate);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLine !== this.props.selectedLine) {
    }
  }

  buildOrder = () => {
    const { selectedLine } = this.props;
    return !selectedLine.isNewProvide
      ? { AccessLineId: selectedLine.accessLineId }
      : {};
  };

  validate = () => {
    const { selectedLine } = this.props;
    const validationMessages = [];

    if (!selectedLine)
      validationMessages.push({
        field: "accessLineSelection",
        message: "Please select a provision type",
      });

    this.setState({ isValid: validationMessages.length === 0 });
    return validationMessages;
  };

  onSelectLine = (line) => {
  
    if (line?.isLineTakeover === false) {
      this.props.onProvisionChange("Migration");
    } else if (line?.isLineTakeover === true) {
      this.props.onProvisionChange("WorkingLineTakeover");
    } else {
      this.props.onProvisionChange("NewProvide");
    }

    const isNewProvide = line?.isNewProvide ?? false;
    this.props.onLineSelected({ ...line, isNewProvide });
  };

  onSelectNewProvide = () => this.onSelectLine({ isNewProvide: true });

  render() {
    const { accessLines, productName, selectedLine, isValid } = this.props;

    const options = accessLines.map((line) => (
      <div key={line.accessLineId} className="access-line-option">
        <AccessLineButton
          accessLine={line}
          onClick={this.onSelectLine}
          selectedLine={selectedLine}
          isValid={isValid}
          productName={productName}
        />
      </div>
    ));

    const selectedClass = selectedLine?.isNewProvide ? "selectedOption" : "";
    const validationClass = !isValid ? "input-validation-error" : "";

    return (
      <Fragment>
        <div className="ui stackable grid">
          <div className="row">
            <div className="column">
              <div className="access-line-buttons">
                {options}
                {!accessLines.some((accessLine) => accessLine.lineType === "StoppedLine") && (
                  <div className="access-line-option">
                    <button
                      className={`ui button ${selectedClass} ${validationClass} ${selectedClass ? "blue" : ""}`}
                      onClick={this.onSelectNewProvide}
                    >
                      New Provide
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const AccessLinesSection = (props) => {

  return (
    <Fragment>
      <AccessLinesSelection {...props} />
    </Fragment>
  );
};

export default AccessLinesSection;